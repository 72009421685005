"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLoaders = exports.destroyControls = exports.createControls = void 0;
var focus_1 = require("./focus");
var main_1 = require("./main");
var stats_1 = require("./stats");
var createControls = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var controls = {
        play: (0, focus_1.createFocusableButton)({
            row: 1,
            column: 0,
            label: 'Play',
            callback: player.play,
        }),
        pause: (0, focus_1.createFocusableButton)({
            row: 1,
            column: 1,
            label: 'Pause',
            callback: player.pause,
        }),
        seekTo0: (0, focus_1.createFocusableButton)({
            row: 1,
            column: 2,
            label: 'Seek to 0',
            callback: player.seekTo0,
        }),
    };
    var controlsContainer = document.getElementById('controls');
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(controls.play);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(controls.pause);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(controls.seekTo0);
};
exports.createControls = createControls;
var destroyControls = function () {
    var controlsContainer = document.getElementById('controls');
    if (!controlsContainer)
        return;
    controlsContainer.innerHTML = '';
};
exports.destroyControls = destroyControls;
var MP4 = 'https://cdn.playsuisse.ch/videos/landing_tv.mp4';
var KALTURA_HLS = 
// eslint-disable-next-line max-len
'https://cdnapisec.kaltura.com/p/2766151/sp/276615100/playManifest/entryId/1_e19l6qx3/format/applehttp/tags/mbr/f/a.m3u8';
var BIBOP_HLS = 'https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_16x9/bipbop_16x9_variant.m3u8';
var STIPO_HLS = 'https://nginx-vod.ps-srgssr.ch/origin/2078657/discontinuity_false/master.m3u8';
var STIPO_DISCONTINUITY_HLS = 'https://nginx-vod.ps-srgssr.ch/origin/2078657/discontinuity_true/master.m3u8';
var createLoaders = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var loaders = {
        mp4: (0, focus_1.createFocusableButton)({
            row: 2,
            column: 0,
            label: 'Load MP4',
            callback: function () {
                (0, stats_1.loadStart)();
                player.load(MP4);
            },
        }),
        kaltura: (0, focus_1.createFocusableButton)({
            row: 2,
            column: 1,
            label: 'Load Kaltura HLS',
            callback: function () {
                (0, stats_1.loadStart)();
                player.load(KALTURA_HLS, 'application/x-mpegURL');
            },
        }),
        bibop: (0, focus_1.createFocusableButton)({
            row: 2,
            column: 2,
            label: 'Load Apple Bibop HLS',
            callback: function () {
                (0, stats_1.loadStart)();
                player.load(BIBOP_HLS, 'application/x-mpegURL');
            },
        }),
        stipo: (0, focus_1.createFocusableButton)({
            row: 2,
            column: 3,
            label: 'Load Stipo HLS',
            callback: function () {
                (0, stats_1.loadStart)();
                player.load(STIPO_HLS, 'application/x-mpegURL');
            },
        }),
        stipoDiscontinuity: (0, focus_1.createFocusableButton)({
            row: 2,
            column: 4,
            label: 'Load Stipo HLS (Discontinuity)',
            callback: function () {
                (0, stats_1.loadStart)();
                player.load(STIPO_DISCONTINUITY_HLS, 'application/x-mpegURL');
            },
        }),
    };
    var controlsContainer = document.getElementById('controls');
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(document.createElement('br'));
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(loaders.mp4);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(loaders.kaltura);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(loaders.bibop);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(loaders.stipo);
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(loaders.stipoDiscontinuity);
};
exports.createLoaders = createLoaders;
