"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LetterboxPlayer = void 0;
require("@srgssr/srgletterbox-web/dist/letterbox.css");
var LetterboxPlayer = /** @class */ (function () {
    function LetterboxPlayer() {
        var _this = this;
        this.play = function () {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.play();
        };
        this.pause = function () {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.pause();
        };
        this.seekTo0 = function () {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.currentTime(0);
        };
        this.load = function (src, type) {
            var _a;
            if (type === void 0) { type = 'video/mp4'; }
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.loadMediaComposition({
                chapterUrn: src,
                chapterList: [
                    {
                        mediaType: 'VIDEO',
                        urn: src,
                        title: 'PlaySuisse',
                        type: 'EPISODE',
                        vendor: 'rio',
                        duration: 1,
                        resourceList: [
                            {
                                url: src,
                                quality: 'HD',
                                protocol: 'HLS',
                                encoding: 'H264',
                                mimeType: type,
                                presentation: 'DEFAULT',
                                streaming: 'HLS',
                                dvr: false,
                                live: false,
                                mediaContainer: 'MP4',
                                audioCodec: 'MP3',
                                videoCodec: 'H264',
                                tokenType: 'NONE',
                            },
                        ],
                    },
                ],
            });
        };
        this.attachEvent = function (event, callback) {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.on(event, callback);
        };
        this.detachEvent = function (event, callback) {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.off(event, callback);
        };
        this.getResolution = function () {
            var _a, _b, _c, _d;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            var currentQualityIndex = (_c = (_a = _this.player) === null || _a === void 0 ? void 0 : (_b = _a.player).qualityLevels) === null || _c === void 0 ? void 0 : _c.call(_b).selectedIndex;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            var currentQualityLevel = currentQualityIndex >= 0 ? (_d = _this.player) === null || _d === void 0 ? void 0 : _d.player.qualityLevels()[currentQualityIndex] : null;
            return currentQualityLevel;
        };
        var letterboxContainer = document.createElement('div');
        letterboxContainer.setAttribute('style', "width: ".concat(window.innerWidth, "px; height: ").concat(window.innerHeight, "px"));
        letterboxContainer.className = 'letterbox-container';
        var videoContainer = document.getElementById('video');
        videoContainer === null || videoContainer === void 0 ? void 0 : videoContainer.appendChild(letterboxContainer);
        this.player = new SRGLetterbox({
            container: '.letterbox-container',
            debug: false,
            noUI: true,
            autoplay: false,
            muted: false,
            components: {
                controlBar: false,
                header: false,
                sharing: false,
                title: false,
                thumbnails: false,
                playButton: false,
                subdivisions: false,
                errorDisplay: false,
            },
            continuousPlayback: {
                default: false,
                storage: false,
            },
            audioTrackLanguage: {
                default: false,
                storage: false,
            },
            textTrackLanguage: {
                default: false,
                storage: false,
            },
        });
        // console.log('this.player.player.options', this.player.player.options_.html5.vhs);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // this.player.player.options_.html5.vhs.overrideNative = false;
        // this.player.player.options_.html5.vhs.enableLowInitialPlaylist = true;
    }
    LetterboxPlayer.prototype.destroy = function () {
        var _a;
        (_a = this.player) === null || _a === void 0 ? void 0 : _a.dispose();
    };
    return LetterboxPlayer;
}());
exports.LetterboxPlayer = LetterboxPlayer;
