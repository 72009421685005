"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoTagPlayer = void 0;
var VideoTagPlayer = /** @class */ (function () {
    function VideoTagPlayer() {
        var _this = this;
        this.play = function () {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.play();
        };
        this.pause = function () {
            var _a;
            (_a = _this.player) === null || _a === void 0 ? void 0 : _a.pause();
        };
        this.seekTo0 = function () {
            _this.player.currentTime = 0;
        };
        this.attachEvent = function (event, callback) {
            _this.player.addEventListener(event, callback);
        };
        this.detachEvent = function (event, callback) {
            _this.player.removeEventListener(event, callback);
        };
        this.load = function (src) {
            _this.player.src = src;
        };
        this.player = document.createElement('video');
        this.player.setAttribute('id', 'player-tag');
        this.player.setAttribute('width', window.innerWidth.toString());
        this.player.setAttribute('height', window.innerHeight.toString());
        var videoContainer = document.getElementById('video');
        videoContainer === null || videoContainer === void 0 ? void 0 : videoContainer.appendChild(this.player);
    }
    VideoTagPlayer.prototype.destroy = function () {
        this.player.pause();
        this.player.setAttribute('src', 'data:video/mp4;base64,AAAAHG...MTAw');
        this.player.load();
        this.player.remove();
    };
    return VideoTagPlayer;
}());
exports.VideoTagPlayer = VideoTagPlayer;
